import React, { useEffect, useMemo, useState } from 'react';
import { Card, Typography, Button, DatePicker, Input, Divider, Row, Col, Select, InputNumber } from 'antd';
import { useStore } from '@/store/store';
import { PaperClipOutlined, SearchOutlined } from '@ant-design/icons';
import theme from '@/styles/customTheme';
import remote from '@/remote/remote';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import dayjs from 'dayjs';
import { LIST_DATE_FORMAT } from '../constant';
import DateRange from './DateRange';

const { Title, Text } = Typography;
const { TextArea } = Input;
const DatePickerFormat = 'dddd, DD MMMM YYYY';

interface ApplyLeaveProps {
  isTeamLeave: boolean;
  leave?: any;
} 

const ApplyLeave = (props: ApplyLeaveProps) => {
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  // const [applyLeaveSettings, setApplyLeaveSettings] = useState<any>({});
  const [applyOptions, setApplyOptions] = useState<any>({});
  const [selectedLeave, setSelectedLeave] = useState<any>({});
  const [selectedLeaveType, setSelectedLeaveType] = useState<any>({});
  const [selectedEmployee, setSelectedEmployee] = useState<any>({});
  // const [leaveReasonOptions, setLeaveReasonOption] = useState<any>({});
  const user = window.Affinity?.user

  const {state, dispatch} = useStore();
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;

  const leaveReasonOptions = useMemo(() => {
    console.log('selectedLeaveType.reasons', selectedLeaveType.reasons)
    if (!selectedLeaveType.reasons) {
      return [];
    }
    return selectedLeaveType.reasons.map((reason: any) => ({
      value: reason.reasonCode,
      label: reason.description,
    }));
  }, [selectedLeaveType.reasons]);

  useEffect(() => {
    if (props.isTeamLeave) {
      dispatch({type: 'SET_SELECTED_LEAVE_DETAIL', payload: selectedLeave})
    } else {
      myDispatch({type: 'SET_SELECTED_LEAVE', payload: selectedLeave})
    }
    
  }, [selectedLeave])

  useEffect(() => {
    const mappedEmployee = state.employees.map((employee) => {
      return {
        value: employee.employeeNo,
        label: employee.employeeName
      }
    })
    console.log(mappedEmployee);
    setEmployeeList(mappedEmployee);
  }, state.employees)

  const approversOptions = useMemo(() => {
    console.log('applyOptions.positions', applyOptions.positions)
    if (!applyOptions.positions) return [];
    const approvers = applyOptions.positions.find((position: any) => position.positionCode === selectedLeave.positionCode)?.submittedTos
    if (!approvers) return [];
    console.log('approversOptions', approvers);
    return approvers.map((approver: any) => ({
      value: approver.employeeNo,
      label: approver.employeeName
    }))
    // return approvers;
    // update approver when position changed
  }, [selectedLeave.positionCode])

  useEffect(() => {
    async function fetchDataInit() {
      console.log('props.leave apply leave', props.leave);

      if (!props.leave) { // new leave
        if (props.isTeamLeave) {
          loadManagerEmployeeList()
          return;
        } else {
          // 
          setLeaveDataBySource(myState.leaveConfig)
          console.log('myState.leaveConfig', myState.leaveConfig)
          if (myState.leaveConfig.positions.length === 1) {
            setSelectedLeave({
              ...selectedLeave,
              employeeNo: user.employeeNo,
              submittedBy: user.employeeNo,
              // leaveCode: myState.leaveConfig.leaveCodes[0].leaveCode,
              positionCode: myState.leaveConfig.positions[0].positionCode,
              positionTitle: myState.leaveConfig.positions[0].positionTitle,
              submittedTo: myState.leaveConfig.positions[0].submittedTos[0].employeeNo,
              authorisations: [myState.leaveConfig.positions[0].submittedTos[0]]
            })
          }
          
        }
      } else { // edit leave
        let employeeData
        if (props.isTeamLeave) {
          setSelectedEmployee(props.leave.employeeNo);
          employeeData = await remote.getLeaveManagerEmployeeConfig(props.leave.employeeNo)
        } else {
          employeeData = myState.leaveConfig;
        }
        setLeaveDataBySource(employeeData);
        setSelectedLeave({
          ...props.leave,
          submittedTo: props.leave.authorisations[0].submittedTo
        });
      }
      
    }
    fetchDataInit();

    // props.leave.leave
  }, [])

  const setLeaveDataBySource = (leaveConfigData: any) => {
    console.log('leaveConfigData', leaveConfigData)
    const data = leaveConfigData;
    setApplyOptions({
      leaveType: data.leaveCodes,
      positions: data.positions,      
    })
    // CurrentUnitsBal
    // Description
    // LeaveCode
    // setApplyLeaveSettings({
    //   singlePosition: data.positions.length === 1,
    //   defaultPosition: data.positions[0],
    //   defaultApprover: data.positions[0].submittedTos[0],
    // })

    console.log('data.leaveCodes', data.leaveCodes)
    if (props.leave) {
      const leaveType = data.leaveCodes.find((leave: any) => leave.leaveCode === props.leave.leaveCode)
      console.log('leaveType', leaveType)
      setSelectedLeaveType(leaveType);
    }
  }

  const loadManagerEmployeeList = () => {
    console.log(state.employees);
    const mappedEmployee = state.employees.map((employee) => {
      return {
        value: employee.employeeNo,
        label: employee.employeeName
      }
    })
    console.log(mappedEmployee);
    setEmployeeList(mappedEmployee);
  }

  const onSelectEmployee = async (employeeNo: any) => {
    console.log('onSelectEmployee', employeeNo);
    setSelectedEmployee(employeeNo);
    const newEmp = employeeList.find((emp) => emp.value === employeeNo)
    console.log('newEmp', newEmp);
    const employeeData = await remote.getLeaveManagerEmployeeConfig(employeeNo)
    setLeaveDataBySource(employeeData);

    if (employeeData.positions.length === 1) {
      setSelectedLeave({
        ...selectedLeave,
        employeeNo: employeeNo,
        submittedBy: employeeNo,
        positionCode: employeeData.positions[0].positionCode,
        positionTitle: employeeData.positions[0].positionTitle,
        submittedTo: employeeData.positions[0].submittedTos[0].employeeNo,
        authorisations: [employeeData.positions[0].submittedTos[0]]
      })
    }
  }

  const onSelectPosition = (position: any) => {
    console.log('onSelectPosition', position);
    setSelectedLeave({
      ...selectedLeave,
      positionCode: position,
      submittedTo: applyOptions.positions.find((pos: any) => pos.positionCode === position)?.submittedTos[0].employeeNo
    })
  }

  const onDateRangeChange = (dateRange: any, dateList: any[], totalDays: number, totalHours: number) => {
    console.log('dateRnage', dateRange)
    console.log('dateList', dateList)
    console.log('totalDays', totalDays)
    if (!dateList || dateList.length === 0) return;
    setSelectedLeave({
      ...selectedLeave,
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
      days: dateList,
      totalHours: totalHours,
      totalDays: totalDays,
    })
  }

  const onSelectReason = (reason: string) => {
    console.log('onSelectReason', reason);
    const selectedReason = selectedLeaveType.reasons.find((val: any) => val.reasonCode === reason);
    setSelectedLeave({
      ...selectedLeave,
      reasonCode: selectedReason.reasonCode,
      reasonDescription: selectedReason.reasonDescription
    })

  }

  const chooseLeaveType = (leaveType: any) => {
    console.log('chooseLeaveType', leaveType);
    selectedLeave.leaveCode
    setSelectedLeave({
      ...selectedLeave,
      leaveCode: leaveType.leaveCode,
      unitType: leaveType.unitType,
      description: leaveType.description,
      reasonCode: leaveType.reasons[0]?.reasonCode,
      // unitType: leave
    })
    setSelectedLeaveType(leaveType);
  }
  
  return (
    <div style={{}}>
      {props.isTeamLeave && (
        <section style={{ padding: "0 10px", marginTop: "20px" }}>
          <div>Employee</div>
          <Select
            style={{ width: "100%", marginTop: "8px" }}
            size="large"
            showSearch
            options={employeeList}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={selectedEmployee}
            onChange={onSelectEmployee}
            placeholder="Select an employee"
          ></Select>
        </section>
      )}

      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <div style={{ marginBottom: "10px" }}>
          <Text type="secondary">Position</Text>
          {/* <Title level={4}>Payroll Manager</Title> */}

          {applyOptions.positions?.length === 1 ? (
            <div style={{ marginTop: "8px" }}>
              {selectedLeave.positionTitle}
            </div>
          ) : (
            <Select
              style={{ width: "100%", marginTop: "8px" }}
              size="large"
              options={applyOptions.positions}
              placeholder="Select an Position"
              value={selectedLeave.positionCode}
              onChange={onSelectPosition}
            ></Select>
          )}
        </div>
      </section>
      <Divider className="leave-divider" />
      <section style={{ padding: "0 10px" }}>
        <Text>Leave Type</Text>
        <div className="leave-type-row">
          {applyOptions.leaveType?.map((leaveType: any) => {
            return (
              <div
                className={`leave-type-card ${
                  leaveType.leaveCode === selectedLeave.leaveCode
                    ? "selected-type-card"
                    : ""
                } `}
                style={{ background: "white" }}
                key={leaveType.leaveCode}
                onClick={() => chooseLeaveType(leaveType)}
              >
                <div>
                  {leaveType.leaveCode}{" "}
                  {`${leaveType.unitType === "H" ? "Hour" : "Days"} available`}
                </div>
                <div>{leaveType.description}</div>
              </div>
            );
          })}
        </div>
      </section>
      <Divider className="leave-divider" />
      {
        selectedLeave.leaveCode
        ? <DateRange selectedLeave={selectedLeave} onDateRangeChange={onDateRangeChange}></DateRange>
        : <h3 style={{marginLeft: '10px'}}>Please select Leave Type First</h3>
      }
      <Divider className="leave-divider" />
      <section style={{ padding: "0 10px" }}>
        <div>Reason</div>
        <Select
          style={{ width: "100%", marginTop: "4px" }}
          options={leaveReasonOptions}
          onChange={onSelectReason}
          value={selectedLeave.reasonCode}
        ></Select>
      </section>
      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <Row justify={"space-between"} align={"middle"}>
          <Text>Attachments</Text>
          <div>
            <Button
              type="text"
              icon={<PaperClipOutlined />}
              style={{ color: theme.color.gray1 }}
            >
              ATTACH FILE
            </Button>
          </div>
        </Row>
        <div className='mt-2'>
          {selectedLeave.attachments?.map((attachment: any, index: number) => (
            <div key={index}>
              <PaperClipOutlined />
              <span>{attachment.name}</span>
            </div>
          ))}
        </div>
      </section>
      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <div>Comments</div>
        <TextArea
          style={{
            marginTop: "8px",
            boxShadow: theme.color.shadow1,
            border: "none",
          }}
          rows={4}
          placeholder="Enter your comment"
        />
      </section>
      <Divider className="leave-divider" />
      <section style={{ marginTop: "10px", padding: "0 10px" }}>
        <div>Approver</div>
        <Select
          options={approversOptions}
          value={selectedLeave.submittedTo}
          style={{ width: "100%", marginTop: "8px" }}
          placeholder="Select an employee"
        ></Select>
      </section>
    </div>
  );
};

export default ApplyLeave;
