import React, { useEffect, useState } from 'react';
import { Button, Tabs, Card, Row, Col, Divider, Typography, Badge, Modal, Select } from 'antd';
import ApplyLeave from './leaveDetail/ApplyLeave';
import { LeaveDetails } from './types';
import MyLeaveTab from './myLeave/MyLeaveTab';
import TeamLeaveTab from './teamLeave/TeamLeaveTab';
import { LoadingOutlined } from '@ant-design/icons';
import { useMyLeaveStore } from '@/store/myLeaveStore';
import EstimateLeave from './estimateLeave/EstimateLeave';
import { useStore } from '@/store/store';
import LeaveCalendar from './calendar/LeaveCalendar';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

enum LeavePan {
  myLeave = 'My Leave',
  teamLeave = 'Team Leave'
}

interface LeaveProps {
  canManageLeave: boolean;
}

const Leave = (props: LeaveProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [leavePan, setLeavePan] = useState<LeavePan>(LeavePan.teamLeave);
  const [userLoaded, setUserLoaded] = useState(false);
  const myStore = useMyLeaveStore();
  const myState = myStore.state;
  const myDispatch = myStore.dispatch;
  // const [teamLeaveList, setTeamLeaveList] = useState([]);

  // add useStore
  const store = useStore(); 
 
  
  const showCreateLeave = () => {
    console.log('showCreateLeave');
    showModal();
  }
  
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    console.log('myState.selectedLeave', myState.selectedLeave);
    if(leavePan === 'Team Leave') {
      store.saveSelectedLeave();
    } else {
      myStore.saveSelectedLeave();
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const changeTab = (key: LeavePan) => {
    setLeavePan(key);
  }

  return (
    <div style={{  }} className='Leave-container'>
      <Row justify="space-between" style={{padding: '20px 20px 0 20px'}} align="middle">
        <Title level={2}>Leave</Title>
        <Button type="primary" style={{ fontSize: '16px' }} onClick={showCreateLeave}>Create</Button>
      </Row>

      <Tabs activeKey={leavePan} style={{ margin: '20px 0 0 0' }} onChange={(key) => changeTab(key as LeavePan)}>
        {
          props.canManageLeave &&
          <TabPane tab="Team Leave" key={LeavePan.teamLeave} className='teamleave-pane'> 
            <TeamLeaveTab></TeamLeaveTab>
          </TabPane>
        }
        
        <TabPane tab="My Leave" className='myleave-pane' key={LeavePan.myLeave}>
          <MyLeaveTab></MyLeaveTab> 
        </TabPane>
        <TabPane tab="Estimated Leave" className='estimatedleave-pane' key="3">
          <EstimateLeave canManage={props.canManageLeave}></EstimateLeave>
        </TabPane>
        <TabPane tab="Leave Calendar" className='leavecalendar-pane' key="4">
          <LeaveCalendar canManage={props.canManageLeave}></LeaveCalendar>
        </TabPane>
      </Tabs>
      <Modal className='full-screen-modal'
        title="Apply Leave"
        open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <ApplyLeave isTeamLeave={leavePan === 'Team Leave'} />
      </Modal>
    </div>
  );
};

export default Leave;
